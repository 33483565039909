import { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Typography } from "@material-tailwind/react";

import { useSocket } from "../App";
import { sdkWrapperURL } from "../utils/api-url-list";

const DownloadReport = () => {
  const { t, i18n } = useTranslation("Report");
  const navigate = useNavigate();
  const { state } = useLocation();
  const scan_id = state?.scan_id;
  const socket = useSocket();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hasConsent, setConsent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [reportSent, setReportSent] = useState(false);

  const requestReport = async () => {
    setLoading(true);
    try {
      const trim_email = email.toLowerCase().trim();
      setEmail(trim_email);
      const trim_name = name.trim();
      setName(trim_name);
      if (trim_email.length <= 0 || trim_name.length <= 0)
        throw new Error("Invalid Form Data");
      const reportResp = await fetch(
        sdkWrapperURL("/users/sdk/manual-report"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("auth_token"),
          },
          body: JSON.stringify({
            scan_id,
            notes: {
              report_slug: `CIP_${i18n.resolvedLanguage.toUpperCase()}`,
              report_webhook_config: {
                tags: [{ name: "patient_name", value: trim_name }],
              },
            },
            email: trim_email,
          }),
        }
      );
      const reportRespJSON = await reportResp.json();
      if (reportRespJSON?.statusCode?.toString().startsWith("2"))
        setReportSent(true);
      else
        throw new Error(
          reportRespJSON?.message ?? "Error in Fetching the Scan Token"
        );
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return scan_id?.length > 0 ? (
    <section className="shrink-0 grow px-[15%]">
      <Typography
        variant="h4"
        className="mb-4 sm:mb-8 text-sm sm:text-2xl text-primary text-center"
      >
        {t("DownloadReport")}
      </Typography>
      {reportSent ? (
        <Typography
          variant="lead"
          className="my-32 text-sm sm:text-2xl text-center text-primary"
        >
          {t("success")}
        </Typography>
      ) : (
        <>
          <Typography
            variant="h4"
            className="mb-4 sm:mb-8 text-sm sm:text-2xl text-primary/80"
          >
            {t("EnterName")}
          </Typography>
          <input
            className="block relative mb-12 sm:mb-24 w-full p-2 sm:p-4 rounded-lg outline-none border border-stroke bg-white text-xs sm:text-lg text-primary"
            type="text"
            name="name"
            id="name"
            placeholder="John Doe"
            autoComplete="off"
            inputMode="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Typography
            variant="h4"
            className="mb-4 sm:mb-8 text-sm sm:text-2xl text-primary/80"
          >
            {t("EnterEmail")}
          </Typography>
          <input
            className="block relative mb-12 sm:mb-24 w-full p-2 sm:p-4 rounded-lg outline-none border border-stroke bg-white text-xs sm:text-lg text-primary"
            type="email"
            name="email"
            id="email"
            placeholder="johndoe@mail.com"
            autoComplete="off"
            inputMode="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Checkbox
            id="consent"
            name="consent"
            color="teal"
            label={t("consent")}
            labelProps={{
              className: "text-primary/80 text-[8px] sm:text-base",
            }}
            containerProps={{ className: "shrink-0" }}
            className="border border-stroke bg-white"
            ripple={true}
            value={hasConsent}
            checked={hasConsent}
            onChange={(e) => setConsent(e.target.checked)}
          />

          <Button
            color="white"
            className="mt-6 sm:mt-8 w-full bg-primary text-white border border-primary text-sm sm:text-lg font-medium normal-case"
            disabled={!hasConsent || isLoading}
            onClick={requestReport}
          >
            {t("DownloadReport")}
          </Button>
        </>
      )}
      <div className="mt-6 sm:mt-8 mb-3 sm:mb-4 flex items-stretch justify-between gap-8">
        <Button
          color="white"
          className="flex-1 bg-white text-primary/80 border border-primary text-sm sm:text-lg font-medium normal-case"
          onClick={() => {
            socket?.emit("playAudio", "reset");
            navigate("/", { replace: true });
          }}
        >
          {t("Cancel")}
        </Button>
        <Button
          color="white"
          className="flex-1 bg-white text-primary/80 border border-primary text-sm sm:text-lg font-medium normal-case"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t("Back")}
        </Button>
      </div>
    </section>
  ) : (
    <Navigate to="/" replace />
  );
};

export default DownloadReport;
