import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    resources: {
      en: {
        translation: {},
        SelectOptions: {
          SelectLanguage: "Select Language",
          consent:
            "The personal data you provide in the report will not be used or stored by Manipal Hospitals or Careplix, nor will it be shared with any third party.",
          Back: "Back",
          Next: "Next",
          Cancel: "Cancel",
          SelectGender: "Select Your Gender",
          Male: "Male",
          Female: "Female",
          SelectDOB: "Enter Your Date of Birth",
          DOB: "Date of Birth",
          YourHeight: "Your Height",
          YourWeight: "Your Weight",
          YourBMI: "Your BMI Result",
          Overweight: "Overweight",
          Underweight: "Underweight",
          Normal: "Normal",
          Obese: "Obese",
        },
        StartScan: {
          ScanHeading: "Digital Health Scan",
          scan_instruction_0:
            "Stay seated or stand still during the scan in well-lit surroundings.",
          scan_instruction_1:
            "Ensure your face is fully visible, free from obstructions like hair, masks, sunglasses, etc.",
          scan_instruction_2:
            "Refrain from any movement or talking during the scan process, stay focused on the screen until the scan is complete.",
          scan_instruction_3:
            "It will take 60-80 seconds to complete the scan.",
          StartScan: "Start Face Scan",
          Back: "Back",
        },
        Scan: {
          initializing: "Initializing",
          noFace: "No Face Detected",
          seconds: "seconds",
          start: "to start",
          completed: "Completed",
          Back: "Back",
          Cancel: "Cancel",
          analyzing_message_0: "ANALYSING DATA...",
          analyzing_message_1: "CLEANING DATA...",
          analyzing_message_2: "EXTRACTING FEATURES...",
          analyzing_message_3: "COMPUTING RESULTS...",
          analyzing_message_4: "PREPARING OUTPUT...",
          analyzing_message_5: "GENERATING FINAL RESULT...",
        },
        Result: {
          DigitalHealthReport: "Your Digital Health Report",
          Mind: "Mind",
          Body: "Body",
          MentalHealthScore: "Mental Health",
          HeartHealthScore: "Heart Health",
          PhysiologicalHealthScore: "Physiological Health",
          OverallHealthScore: "Overall Health Score",
          ScanResult: "Scan Result",
          VitalsDenotation: "Vitals Denotation",
          KeyBodyVitals: "Key Body Vitals",
          HeartHealth: "Heart Health",
          Physiological: "Physiological",
          StressANS: "Stress & ANS",
          DownloadReport: "Download Report",
          Cancel: "Cancel",
        },
        LargeScreen: {
          initializing: "Initializing",
          status: "STATUS",
          calibrating: "CALIBRATING",
          seconds: "SECONDS",
          start: "TO START",
          scanning: "SCAN IN PROGRESS",
          completed: "COMPLETED",
          faceNotDetected: "PLEASE PLACE YOUR FACE TO CONTINUE",
          error: "ERROR OCCURRED",
          failed: "SCAN FAILED",
          analyzing: "ANALYSING",
          finished: "SCAN COMPLETED",
          analyzing_message_0: "ANALYSING DATA...",
          analyzing_message_1: "CLEANING DATA...",
          analyzing_message_2: "EXTRACTING FEATURES...",
          analyzing_message_3: "COMPUTING RESULTS...",
          analyzing_message_4: "PREPARING OUTPUT...",
          analyzing_message_5: "GENERATING FINAL RESULT...",
          DigitalHealthReport: "Your Digital Health Report",
          Mind: "Mind",
          Body: "Body",
          MentalHealthScore: "Mental Health",
          HeartHealthScore: "Heart Health",
          PhysiologicalHealthScore: "Physiological Health",
          OverallHealthScore: "Overall Health Score",
          KeyBodyVitals: "Key Body Vitals",
          HeartHealth: "Heart Health",
          Physiological: "Physiological",
          StressANS: "Stress & ANS",
        },
        Report: {
          DownloadReport: "Download Report",
          EnterName: "Enter Your Name",
          EnterEmail: "Enter Your Email ID",
          consent:
            "I confirm that Manipal Hospitals or Careplix can use and store my contact details to send me the completed report, as well as relevant company updates and insights in the future.",
          success:
            "We've successfully captured your email address. You can expect to receive the report from us shortly. Thank you!",
          SendReport: "Send Report",
          Cancel: "Cancel",
          Back: "Back",
        },
      },
      // ar: {
      //   translation: {},
      //   SelectOptions: {
      //     SelectLanguage: "اختار اللغة",
      //     consent:
      //       "لن يتم استخدام البيانات الشخصية التي تقدمها في التقرير أو تخزينها بواسطة E& Enterprise، ولن تتم مشاركتها مع أي طرف ثالث.",
      //     Back: "رجوع",
      //     Next: "التالي",
      //     Cancel: "الغاء",
      //     SelectGender: "اختر جنسك",
      //     Male: "ذكر",
      //     Female: "أنثى",
      //     SelectDOB: "أدخل تاريخ ميلادك",
      //     DOB: "تاريخ الميلاد",
      //     YourHeight: "طولك",
      //     YourWeight: "وزنك",
      //     YourBMI: "نتيجة مؤشر كتلة الجسم الخاص بك",
      //     Overweight: "زيادة في الوزن",
      //     Underweight: "انخفاض في الوزن",
      //     Normal: "طبيعي",
      //     Obese: "السمنة",
      //   },
      //   StartScan: {
      //     ScanHeading: "المسح الصحي الرقمي",
      //     scan_instruction_0:
      //       "ابق جالسًا أو واقفًا دوم حركة أثناء إجراء الفحص في بيئة مضاءة جيدًا.",
      //     scan_instruction_1:
      //       "تأكد من أن وجهك مرئي بالكامل وخالي من العوائق مثل الشعر والأقنعة والنظارات الشمسية وما إلى ذلك.",
      //     scan_instruction_2:
      //       "امتنع عن أي حركة أو كلام أثناء عملية المسح، وركز على الشاشة حتى انتهاء الفحص.",
      //     scan_instruction_3:
      //       "سوف يستغرق الأمر من 60 إلى 80 ثانية لإكمال الفحص.",
      //     StartScan: "ابدأ بمسح الوجه",
      //     Back: "رجوع",
      //   },
      //   Scan: {
      //     initializing: "التهيئة",
      //     noFace: "لم يتم اكتشاف أي وجه",
      //     seconds: "ثواني",
      //     start: "للبدأ",
      //     completed: "مكتمل",
      //     Back: "رجوع",
      //     Cancel: "الغاء",
      //     analyzing_message_0: "جارٍ تحليل البيانات...",
      //     analyzing_message_1: "جارٍ تنظيف البيانات...",
      //     analyzing_message_2: "استخراج الميزات...",
      //     analyzing_message_3: "حساب النتائج...",
      //     analyzing_message_4: "جارٍ تحضير المخرجات...",
      //     analyzing_message_5: "توليد النتيجة النهائية...",
      //   },
      //   Result: {
      //     DigitalHealthReport: "تقرير الصحة الرقمية الخاص بك",
      //     Mind: "العقل",
      //     Body: "الجسم",
      //     MentalHealthScore: "الصحة النفسية",
      //     HeartHealthScore: "صحة القلب",
      //     PhysiologicalHealthScore: "الصحة الفسيولوجية",
      //     OverallHealthScore: "النتيجة الصحية الشاملة",
      //     ScanResult: "نتيجة المسح",
      //     VitalsDenotation: "دلالة الحيوية",
      //     KeyBodyVitals: "المؤشرات الحيوية الرئيسية",
      //     HeartHealth: "صحة القلب",
      //     Physiological: "صحة وظائف الجسد الفسيولوجية",
      //     StressANS: "الإجهاد و الجهاز العصبي الاارادي",
      //     DownloadReport: "تحميل التقرير",
      //     Cancel: "الغاء",
      //   },
      //   LargeScreen: {
      //     initializing: "التهيئة",
      //     status: "الوضع",
      //     calibrating: "المعايرة",
      //     seconds: "ثواني",
      //     start: "للبدأ",
      //     scanning: "المسح قيد التقدم",
      //     completed: "مكتمل",
      //     faceNotDetected: "يرجى وضع وجهك للمتابعة",
      //     analyzing: "جارٍ التحليل",
      //     finished: "اكتمل الفحص",
      //     analyzing_message_0: "جارٍ تحليل البيانات...",
      //     analyzing_message_1: "جارٍ تنظيف البيانات...",
      //     analyzing_message_2: "استخراج الميزات...",
      //     analyzing_message_3: "حساب النتائج...",
      //     analyzing_message_4: "جارٍ تحضير المخرجات...",
      //     analyzing_message_5: "توليد النتيجة النهائية...",
      //     DigitalHealthReport: "تقرير الصحة الرقمية الخاص بك",
      //     Mind: "العقل",
      //     Body: "الجسم",
      //     MentalHealthScore: "الصحة النفسية",
      //     HeartHealthScore: "صحة القلب",
      //     PhysiologicalHealthScore: "الصحة الفسيولوجية",
      //     OverallHealthScore: "النتيجة الصحية الشاملة",
      //     KeyBodyVitals: "المؤشرات الحيوية الرئيسية",
      //     HeartHealth: "صحة القلب",
      //     Physiological: "صحة وظائف الجسد الفسيولوجية",
      //     StressANS: "الإجهاد و الجهاز العصبي الاارادي",
      //   },
      //   Report: {
      //     DownloadReport: "تنزيل التقرير",
      //     EnterName: "أدخل أسمك",
      //     EnterEmail: "أدخل البريد الإلكتروني الخاص بك",
      //     consent:
      //       "أؤكد أنه يمكن لشركة e&Enterprise استخدام وتخزين تفاصيل الاتصال الخاصة بي لإرسال التقرير المكتمل إليّ، بالإضافة إلى تحديثات الشركة والرؤى ذات الصلة في المستقبل.",
      //     success:
      //       "لقد نجحنا في الحصول على عنوان بريدك الإلكتروني. ويمكنك أن تتوقع تلقي التقرير منا قريبًا. شكرًا لك!",
      //     SendReport: "إرسال تقرير",
      //     Cancel: "الغاء",
      //     Back: "عودة",
      //   },
      // },
      // ur: {
      //   translation: {},
      //   SelectOptions: {
      //     SelectLanguage: "زبان منتخب کریں۔",
      //     consent:
      //       "رپورٹ میں آپ جو ذاتی ڈیٹا فراہم کرتے ہیں وہ E& Enterprise کے ذریعے استعمال یا ذخیرہ نہیں کیا جائے گا، اور نہ ہی اسے کسی تیسرے فریق کے ساتھ شیئر کیا جائے گا۔",
      //     Back: "پیچھے",
      //     Next: "اگلے",
      //     Cancel: "منسوخ کریں۔",
      //     SelectGender: "اپنی جنس منتخب کریں۔",
      //     Male: "مرد",
      //     Female: "عورت",
      //     SelectDOB: "اپنی تاریخ پیدائش درج کریں۔",
      //     DOB: "پیدائش کی تاریخ",
      //     YourHeight: "آپ کا قد",
      //     YourWeight: "آپ کا وزن",
      //     YourBMI: "آپ کا BMI نتیجہ",
      //     Overweight: "زیادہ وزن",
      //     Underweight: "کم وزن",
      //     Normal: "معمول",
      //     Obese: "موٹاپا",
      //   },
      //   StartScan: {
      //     ScanHeading: "ڈیجیٹل ہیلتھ اسکین",
      //     scan_instruction_0:
      //       "اچھی روشنی والے ماحول میں اسکین کے دوران بیٹھے رہیں یا خاموش کھڑے رہیں۔",
      //     scan_instruction_1:
      //       "یقینی بناءے کہ اپکا چہرہ مکمل طور پر نظر آے کسی بھی طرح کا رکاوٹ کا باعث نہ بنے۔ جیسے عینک بال یا ماسک وغیرہ",
      //     scan_instruction_2:
      //       "اسکین کے عمل کے دوران کسی بھی حرکت یا بات کرنے سے گریز کریں، اسکین مکمل ہونے تک اسکرین پر توجہ مرکوز رکھیں۔",
      //     scan_instruction_3: "اسکین مکمل ہونے میں 60-80 سیکنڈ لگیں گے۔",
      //     StartScan: "چہرہ اسکین شروع کریں۔",
      //     Back: "پیچھے",
      //   },
      //   Scan: {
      //     initializing: "ابتدا کرنا",
      //     noFace: "کوئی چہرہ دریافت نہیں ہوا",
      //     seconds: "سیکنڈز",
      //     start: "شروع کرنے کے لئے",
      //     completed: "مکمل",
      //     Back: "پیچھے",
      //     Cancel: "منسوخ کریں۔",
      //     analyzing_message_0: "ڈیٹا کا تجزیہ کیا جا رہا ہے...",
      //     analyzing_message_1: "ڈیٹا کو صاف کیا جا رہا ہے...",
      //     analyzing_message_2: "خصوصیات کو نکالا جا رہا ہے...",
      //     analyzing_message_3: "کمپیوٹنگ کے نتائج...",
      //     analyzing_message_4: "آؤٹ پٹ تیار ہو رہا ہے...",
      //     analyzing_message_5: "حتمی نتیجہ تیار کیا جا رہا ہے...",
      //   },
      //   Result: {
      //     DigitalHealthReport: "آپ کی ڈیجیٹل ہیلتھ رپورٹ",
      //     Mind: "دماغ",
      //     Body: "جسم",
      //     MentalHealthScore: "دماغی صحت",
      //     HeartHealthScore: "دل کی صحت",
      //     PhysiologicalHealthScore: "جسمانی صحت",
      //     OverallHealthScore: "مجموعی صحت کا اسکور",
      //     ScanResult: "اسکین کا نتیجہ",
      //     VitalsDenotation: "وائٹلز ڈینیوٹیشن",
      //     KeyBodyVitals: "کلیدی جسمانی وائٹلز",
      //     HeartHealth: "دل کی صحت",
      //     Physiological: "جسمانی",
      //     StressANS: "تناؤ اور اے این ایس",
      //     DownloadReport: "رپورٹ ڈاؤن لوڈ کریں۔",
      //     Cancel: "منسوخ کریں۔",
      //   },
      //   LargeScreen: {
      //     initializing: "ابتدا کرنا",
      //     status: "درجہ",
      //     calibrating: "کیلیبریٹنگ",
      //     seconds: "سیکنڈز",
      //     start: "شروع کرنے کے لئے",
      //     scanning: "اسکین جاری ہے۔",
      //     completed: "مکمل",
      //     faceNotDetected: "براہ کرم جاری رکھنے کے لیے اپنا چہرہ رکھیں",
      //     analyzing: "تجزیہ کیا جا رہا ہے",
      //     finished: "اسکین مکمل ہو گیا۔",
      //     analyzing_message_0: "ڈیٹا کا تجزیہ کیا جا رہا ہے...",
      //     analyzing_message_1: "ڈیٹا کو صاف کیا جا رہا ہے...",
      //     analyzing_message_2: "خصوصیات کو نکالا جا رہا ہے...",
      //     analyzing_message_3: "کمپیوٹنگ کے نتائج...",
      //     analyzing_message_4: "آؤٹ پٹ تیار ہو رہا ہے...",
      //     analyzing_message_5: "حتمی نتیجہ تیار کیا جا رہا ہے...",
      //     DigitalHealthReport: "آپ کی ڈیجیٹل ہیلتھ رپورٹ",
      //     Mind: "دماغ",
      //     Body: "جسم",
      //     MentalHealthScore: "دماغی صحت",
      //     HeartHealthScore: "دل کی صحت",
      //     PhysiologicalHealthScore: "جسمانی صحت",
      //     OverallHealthScore: "مجموعی صحت کا اسکور",
      //     KeyBodyVitals: "کلیدی جسمانی وائٹلز",
      //     HeartHealth: "دل کی صحت",
      //     Physiological: "جسمانی",
      //     StressANS: "تناؤ اور اے این ایس",
      //   },
      //   Report: {
      //     DownloadReport: "رپورٹ ڈاؤن لوڈ کریں۔",
      //     EnterName: "اپنا نام درج کریں",
      //     EnterEmail: "اپنی ای میل آئی ڈی درج کریں۔",
      //     consent:
      //       "میں تصدیق کرتا ہوں کہ e&enterprise مجھے مکمل شدہ رپورٹ بھیجنے کے لیے میرے رابطے کی تفصیلات کا استعمال اور ذخیرہ کر سکتا ہے، ساتھ ہی ساتھ مستقبل میں متعلقہ کمپنی کی اپ ڈیٹس اور بصیرتیں بھی بھیج سکتا ہے۔",
      //     success:
      //       "ہم نے کامیابی سے آپ کا ای میل پتہ حاصل کر لیا ہے۔ آپ جلد ہی ہم سے رپورٹ موصول ہونے کی توقع کر سکتے ہیں۔ شکریہ!",
      //     SendReport: "رپورٹ بھیجیں",
      //     Cancel: "منسوخ کریں۔",
      //     Back: "پیچھے",
      //   },
      // },
      // es: {
      //   translation: {},
      //   SelectOptions: {
      //     SelectLanguage: "Seleccione el idioma",
      //     consent:
      //       "E& Enterprise no utilizará ni almacenará los datos personales que proporcione en el informe, ni los compartirá con ningún tercero.",
      //     Back: "Atrás",
      //     Next: "Próximo",
      //     Cancel: "Cancelar",
      //     SelectGender: "Selecciona tu género",
      //     Male: "Masculino",
      //     Female: "Femenina",
      //     SelectDOB: "Introduzca su fecha de nacimiento",
      //     DOB: "Fecha de nacimiento",
      //     YourHeight: "Tu altura",
      //     YourWeight: "Tu peso",
      //     YourBMI: "Su resultado de BMI",
      //     Overweight: "Sobrepeso",
      //     Underweight: "Bajo peso",
      //     Normal: "Normal",
      //     Obese: "Obeso",
      //   },
      //   StartScan: {
      //     ScanHeading: "Escaneo de salud digital",
      //     scan_instruction_0:
      //       "Permanezca sentado o quédese quieto durante la exploración en un entorno bien iluminado.",
      //     scan_instruction_1:
      //       "Asegúrese de que su rostro esté completamente visible, libre de obstrucciones como cabello, máscaras, gafas de sol, etc.",
      //     scan_instruction_2:
      //       "Abstenerse de cualquier movimiento o hablar durante el proceso de escaneo, manténgase concentrado en la pantalla hasta que se complete el escaneo.",
      //     scan_instruction_3:
      //       "Tardará entre 60 y 80 segundos en completar el escaneo.",
      //     StartScan: "Iniciar escaneo facial",
      //     Back: "Atrás",
      //   },
      //   Scan: {
      //     initializing: "INICIALIZANDO",
      //     noFace: "NO SE DETECTÓ CARA",
      //     seconds: "SEGUNDOS",
      //     start: "PARA COMENZAR",
      //     completed: "TERMINADA",
      //     Back: "Atrás",
      //     Cancel: "Cancelar",
      //     analyzing_message_0: "ANALIZANDO DATOS...",
      //     analyzing_message_1: "DATOS DE LIMPIEZA...",
      //     analyzing_message_2: "EXTRACCIÓN DE CARACTERÍSTICAS...",
      //     analyzing_message_3: "RESULTADOS DE COMPUTACIÓN...",
      //     analyzing_message_4: "PREPARANDO LA SALIDA...",
      //     analyzing_message_5: "GENERANDO RESULTADO FINAL...",
      //   },
      //   Result: {
      //     DigitalHealthReport: "Su informe de salud digital",
      //     Mind: "Mente",
      //     Body: "Cuerpo",
      //     MentalHealthScore: "Salud mental",
      //     HeartHealthScore: "Salud de corazon",
      //     PhysiologicalHealthScore: "Salud fisiológica",
      //     OverallHealthScore: "Puntuación de salud general",
      //     ScanResult: "Resultado del escaneo",
      //     VitalsDenotation: "Denotación de vitales",
      //     KeyBodyVitals: "Vitales clave del cuerpo",
      //     HeartHealth: "Salud de corazon",
      //     Physiological: "Fisiológica",
      //     StressANS: "Estrés y ANS",
      //     DownloadReport: "Descargar Informe",
      //     Cancel: "Cancelar",
      //   },
      //   LargeScreen: {
      //     initializing: "INICIALIZANDO",
      //     status: "ESTADO",
      //     calibrating: "CALIBRANDO",
      //     seconds: "SEGUNDOS",
      //     start: "PARA COMENZAR",
      //     scanning: "ESCANEO EN CURSO",
      //     completed: "TERMINADA",
      //     faceNotDetected: "POR FAVOR COLOQUE SU CARA PARA CONTINUAR",
      //     analyzing: "ANALIZANDO",
      //     finished: "ESCANEO COMPLETADO",
      //     analyzing_message_0: "ANALIZANDO DATOS...",
      //     analyzing_message_1: "DATOS DE LIMPIEZA...",
      //     analyzing_message_2: "EXTRACCIÓN DE CARACTERÍSTICAS...",
      //     analyzing_message_3: "RESULTADOS DE COMPUTACIÓN...",
      //     analyzing_message_4: "PREPARANDO LA SALIDA...",
      //     analyzing_message_5: "GENERANDO RESULTADO FINAL...",
      //     DigitalHealthReport: "Su informe de salud digital",
      //     Mind: "Mente",
      //     Body: "Cuerpo",
      //     MentalHealthScore: "Salud mental",
      //     HeartHealthScore: "Salud de corazon",
      //     PhysiologicalHealthScore: "Salud fisiológica",
      //     OverallHealthScore: "Puntuación de salud general",
      //     KeyBodyVitals: "Vitales clave del cuerpo",
      //     HeartHealth: "Salud de corazon",
      //     Physiological: "Fisiológica",
      //     StressANS: "Estrés y ANS",
      //   },
      //   Report: {
      //     DownloadReport: "Descargar Informe",
      //     EnterName: "Introduzca su nombre",
      //     EnterEmail: "Ingrese su ID de correo electrónico",
      //     consent:
      //       "Confirmo que e& Enterprise puede usar y almacenar mis datos de contacto para enviarme el informe completo, así como actualizaciones e información relevante de la empresa en el futuro.",
      //     success:
      //       "Hemos capturado con éxito su dirección de correo electrónico. Puede esperar recibir nuestro informe en breve. ¡Gracias!",
      //     SendReport: "Enviar reporte",
      //     Cancel: "Cancelar",
      //     Back: "Atrás",
      //   },
      // },
      hi: {
        translation: {},
        SelectOptions: {
          SelectLanguage: "भाषा का चयन करें",
          consent:
            "रिपोर्ट में आपके द्वारा प्रदान किया गया व्यक्तिगत डेटा Manipal Hospitals or Careplix द्वारा उपयोग या संग्रहीत नहीं किया जाएगा, न ही इसे किसी तीसरे पक्ष के साथ साझा किया जाएगा।",
          Back: "पीछे",
          Next: "आगे",
          Cancel: "रद्द करें",
          SelectGender: "अपने लिंग का चयन करें",
          Male: "पुरुष",
          Female: "महिला",
          SelectDOB: "अपनी जन्म तिथि दर्ज करें",
          DOB: "जन्म तिथि",
          YourHeight: "आपका कद",
          YourWeight: "आपका वज़न",
          YourBMI: "आपका बीएमआई परिणाम",
          Overweight: "अधिक वजन",
          Underweight: "कम वजन",
          Normal: "सामान्य",
          Obese: "मोटापा",
        },
        StartScan: {
          ScanHeading: "डिजिटल स्वास्थ्य स्कैन",
          scan_instruction_0:
            "अच्छी तरह से रोशनी वाले परिवेश में स्कैन के दौरान बैठे रहें या खड़े रहें.",
          scan_instruction_1:
            "सुनिश्चित करें कि आपका चेहरा पूरी तरह से दिखाई दे, बाल, मास्क, धूप का चश्मा आदि जैसे अवरोधों से मुक्त हो.",
          scan_instruction_2:
            "स्कैन प्रक्रिया के दौरान किसी भी गतिविधि या बात करने से बचें, स्कैन पूरा होने तक स्क्रीन पर केंद्रित रहें.",
          scan_instruction_3: "स्कैन पूरा करने में 60-80 सेकंड का समय लगेगा।",
          StartScan: "फेस स्कैन शुरू करें",
          Back: "पीछे",
        },
        Scan: {
          initializing: "आरंभीकरण",
          noFace: "कोई चेहरा नहीं मिला",
          seconds: "सेकंड",
          start: "शुरू करने के लिए",
          completed: "पूरा हुआ",
          Back: "पीछे",
          Cancel: "रद्द करें",
          analyzing_message_0: "डेटा का विश्लेषण किया जा रहा है...",
          analyzing_message_1: "डेटा साफ़ किया जा रहा है...",
          analyzing_message_2: "विशेषताएं निकाली जा रही हैं...",
          analyzing_message_3: "गणना परिणाम...",
          analyzing_message_4: "आउटपुट तैयार किया जा रहा है...",
          analyzing_message_5: "अंतिम परिणाम तैयार किया जा रहा है...",
        },
        Result: {
          DigitalHealthReport: "आपका डिजिटल हेल्थ रिपोर्ट",
          Mind: "दिमाग",
          Body: "शरीर",
          MentalHealthScore: "मानसिक स्वास्थ्य",
          HeartHealthScore: "हृदय स्वास्थ्य",
          PhysiologicalHealthScore: "शारीरिक स्वास्थ्य",
          OverallHealthScore: "समग्र स्वास्थ्य स्कोर",
          ScanResult: "स्कैन परिणाम",
          VitalsDenotation: "वाइटल्स परिभाषा",
          KeyBodyVitals: "मुख्य शारीरिक वाइटल",
          HeartHealth: "हृदय स्वास्थ्य",
          Physiological: "शारीरिक",
          StressANS: "तनाव और एएनएस",
          DownloadReport: "रिपोर्ट डाउनलोड करें",
          Cancel: "रद्द करें",
        },
        LargeScreen: {
          initializing: "आरंभीकरण",
          status: "दर्जा",
          calibrating: "अंशांकन",
          seconds: "सेकंड",
          start: "शुरू करने के लिए",
          scanning: "स्कैन प्रगति पर है",
          completed: "पूरा हुआ",
          faceNotDetected: "कृपया जारी रखने के लिए अपना चेहरा रखें",
          analyzing: "विश्लेषण किया जा रहा है",
          finished: "स्कैन पूरा हुआ",
          analyzing_message_0: "डेटा का विश्लेषण किया जा रहा है...",
          analyzing_message_1: "डेटा साफ़ किया जा रहा है...",
          analyzing_message_2: "विशेषताएं निकाली जा रही हैं...",
          analyzing_message_3: "गणना परिणाम...",
          analyzing_message_4: "आउटपुट तैयार किया जा रहा है...",
          analyzing_message_5: "अंतिम परिणाम तैयार किया जा रहा है...",
          DigitalHealthReport: "आपका डिजिटल हेल्थ रिपोर्ट",
          Mind: "दिमाग",
          Body: "शरीर",
          MentalHealthScore: "मानसिक स्वास्थ्य",
          HeartHealthScore: "हृदय स्वास्थ्य",
          PhysiologicalHealthScore: "शारीरिक स्वास्थ्य",
          OverallHealthScore: "समग्र स्वास्थ्य स्कोर",
          KeyBodyVitals: "मुख्य शारीरिक वाइटल",
          HeartHealth: "हृदय स्वास्थ्य",
          Physiological: "शारीरिक",
          StressANS: "तनाव और एएनएस",
        },
        Report: {
          DownloadReport: "रिपोर्ट डाउनलोड करें",
          EnterName: "अपना नाम दर्ज करें",
          EnterEmail: "अपना ईमेल आईडी दर्ज करें",
          consent:
            "मैं पुष्टि करता हूं कि Manipal Hospitals or Careplix मुझे पूरी रिपोर्ट, साथ ही भविष्य में संबंधित कंपनी अपडेट और अंतर्दृष्टि भेजने के लिए मेरे संपर्क विवरण का उपयोग और भंडारण कर सकता है।",
          success:
            "हमने आपके ईमेल पते का सफलतापूर्वक प्रग्रहण कर लिया है. आप शीघ्र ही हमसे रिपोर्ट प्राप्त करने की उम्मीद कर सकते हैं. धन्यवाद!",
          SendReport: "रिपोर्ट भेजें",
          Cancel: "रद्द करें",
          Back: "पीछे",
        },
      },
      // ml: {
      //   translation: {},
      //   SelectOptions: {
      //     SelectLanguage: "ഭാഷ തിരഞ്ഞെടുക്കുക",
      //     consent:
      //       "റിപ്പോർട്ടിൽ നിങ്ങൾ നൽകുന്ന വ്യക്തിഗത ഡാറ്റ E& Enterprise ഉപയോഗിക്കുകയോ സംഭരിക്കുകയോ ചെയ്യില്ല, ഏതെങ്കിലും മൂന്നാം കക്ഷിയുമായി പങ്കിടുകയുമില്ല.",
      //     Back: "തിരികെ",
      //     Next: "അടുത്തത്",
      //     Cancel: "റദ്ദാക്കുക",
      //     SelectGender: "നിങ്ങളുടെ ലിംഗഭേദം തിരഞ്ഞെടുക്കുക",
      //     Male: "പുരുഷൻ",
      //     Female: "സ്ത്രീ",
      //     SelectDOB: "നിങ്ങളുടെ ജനനത്തീയതി നൽകുക",
      //     DOB: "ജനനത്തീയതി",
      //     YourHeight: "നിന്റെ ഉയരം",
      //     YourWeight: "നിങ്ങളുടെ തൂക്കം",
      //     YourBMI: "നിങ്ങളുടെ BMI ഫലം",
      //     Overweight: "അമിതഭാരം",
      //     Underweight: "ഭാരക്കുറവ്",
      //     Normal: "സാധാരണ",
      //     Obese: "പൊണ്ണത്തടി",
      //   },
      //   StartScan: {
      //     ScanHeading: "ഡിജിറ്റൽ ഹെൽത്ത് സ്കാൻ",
      //     scan_instruction_0:
      //       "നല്ല വെളിച്ചമുള്ള ചുറ്റുപാടിൽ സ്കാൻ ചെയ്യുമ്പോൾ ഇരിക്കുകയോ നിശ്ചലമായി നിൽക്കുകയോ ചെയ്യുക.",
      //     scan_instruction_1:
      //       "മുടി, മുഖംമൂടികൾ, സൺഗ്ലാസുകൾ തുടങ്ങിയവ പോലുള്ള തടസ്സങ്ങളിൽ നിന്ന് നിങ്ങളുടെ മുഖം പൂർണ്ണമായി ദൃശ്യമാണെന്ന് ഉറപ്പാക്കുക.",
      //     scan_instruction_2:
      //       "സ്കാൻ ചെയ്യുന്ന സമയത്ത് ഏതെങ്കിലും സംസാരിക്കാതെ ചലിക്കാതെ നിശ്ചലമായി നിൽക്കുക, സ്കാൻ പൂർത്തിയാകുന്നതുവരെ സ്ക്രീനിൽ ശ്രദ്ധ കേന്ദ്രീകരിക്കുക.",
      //     scan_instruction_3: "സ്കാൻ പൂർത്തിയാക്കാൻ 60-80 സെക്കൻഡ് എടുക്കും.",
      //     StartScan: "ഫേസ് സ്കാൻ ആരംഭിക്കുക",
      //     Back: "തിരികെ",
      //   },
      //   Scan: {
      //     initializing: "തുടക്കമിടുന്നു",
      //     noFace: "മുഖമൊന്നും കണ്ടെത്തിയില്ല",
      //     seconds: "സെക്കൻഡ്",
      //     start: "ആരംഭിക്കാൻ",
      //     completed: "പൂർത്തീകരിച്ചു",
      //     Back: "തിരികെ",
      //     Cancel: "റദ്ദാക്കുക",
      //     analyzing_message_0: "ഡാറ്റ വിശകലനം ചെയ്യുന്നു...",
      //     analyzing_message_1: "ഡാറ്റ വൃത്തിയാക്കുന്നു...",
      //     analyzing_message_2: "ഫീച്ചറുകൾ എക്‌സ്‌ട്രാക്റ്റുചെയ്യുന്നു...",
      //     analyzing_message_3: "കമ്പ്യൂട്ടിംഗ് ഫലങ്ങൾ...",
      //     analyzing_message_4: "ഔട്ട്പുട്ട് തയ്യാറാക്കുന്നു...",
      //     analyzing_message_5: "അന്തിമഫലം സൃഷ്ടിക്കുന്നു...",
      //   },
      //   Result: {
      //     DigitalHealthReport: "നിങ്ങളുടെ ഡിജിറ്റൽ ആരോഗ്യ റിപ്പോർട്ട്",
      //     Mind: "മനസ്സ്",
      //     Body: "ശരീരം",
      //     MentalHealthScore: "മാനസികാരോഗ്യം",
      //     HeartHealthScore: "ഹൃദയാരോഗ്യം",
      //     PhysiologicalHealthScore: "ഫിസിയോളജിക്കൽ ഹെൽത്ത്",
      //     OverallHealthScore: "മൊത്തത്തിലുള്ള ആരോഗ്യ സ്കോർ",
      //     ScanResult: "സ്കാൻ ഫലം",
      //     VitalsDenotation: "വൈറ്റൽസ് ഡിനോട്ടേഷൻ",
      //     KeyBodyVitals: "പ്രധാന ശരീര ജീവകങ്ങൾ",
      //     HeartHealth: "ഹൃദയാരോഗ്യം",
      //     Physiological: "ഫിസിയോളജിക്കൽ",
      //     StressANS: "സമ്മർദ്ദവും എഎൻഎസും",
      //     DownloadReport: "റിപ്പോർട്ട് ഡൗൺലോഡ് ചെയ്യുക",
      //     Cancel: "റദ്ദാക്കുക",
      //   },
      //   LargeScreen: {
      //     initializing: "തുടക്കമിടുന്നു",
      //     status: "സ്റ്റാറ്റസ്",
      //     calibrating: "കാലിബ്രേറ്റിംഗ്",
      //     seconds: "സെക്കൻഡ്",
      //     start: "ആരംഭിക്കാൻ",
      //     scanning: "സ്കാൻ പുരോഗമിക്കുന്നു",
      //     completed: "പൂർത്തീകരിച്ചു",
      //     faceNotDetected: "തുടരാൻ നിങ്ങളുടെ മുഖം വയ്ക്കുക",
      //     analyzing: "വിശകലനം ചെയ്യുന്നു",
      //     finished: "സ്കാൻ പൂർത്തിയായി",
      //     analyzing_message_0: "ഡാറ്റ വിശകലനം ചെയ്യുന്നു...",
      //     analyzing_message_1: "ഡാറ്റ വൃത്തിയാക്കുന്നു...",
      //     analyzing_message_2: "ഫീച്ചറുകൾ എക്‌സ്‌ട്രാക്റ്റുചെയ്യുന്നു...",
      //     analyzing_message_3: "കമ്പ്യൂട്ടിംഗ് ഫലങ്ങൾ...",
      //     analyzing_message_4: "ഔട്ട്പുട്ട് തയ്യാറാക്കുന്നു...",
      //     analyzing_message_5: "അന്തിമഫലം സൃഷ്ടിക്കുന്നു...",
      //     DigitalHealthReport: "നിങ്ങളുടെ ഡിജിറ്റൽ ആരോഗ്യ റിപ്പോർട്ട്",
      //     Mind: "മനസ്സ്",
      //     Body: "ശരീരം",
      //     MentalHealthScore: "മാനസികാരോഗ്യം",
      //     HeartHealthScore: "ഹൃദയാരോഗ്യം",
      //     PhysiologicalHealthScore: "ഫിസിയോളജിക്കൽ ഹെൽത്ത്",
      //     OverallHealthScore: "മൊത്തത്തിലുള്ള ആരോഗ്യ സ്കോർ",
      //     KeyBodyVitals: "പ്രധാന ശരീര ജീവകങ്ങൾ",
      //     HeartHealth: "ഹൃദയാരോഗ്യം",
      //     Physiological: "ഫിസിയോളജിക്കൽ",
      //     StressANS: "സമ്മർദ്ദവും എഎൻഎസും",
      //   },
      //   Report: {
      //     DownloadReport: "റിപ്പോർട്ട് ഡൗൺലോഡ് ചെയ്യുക",
      //     EnterName: "നിങ്ങളുടെ പേര് നൽകുക",
      //     EnterEmail: "നിങ്ങളുടെ ഇമെയിൽ ഐഡി നൽകുക",
      //     consent:
      //       "പൂർത്തിയാക്കിയ റിപ്പോർട്ടും ഭാവിയിൽ പ്രസക്തമായ കമ്പനി അപ്‌ഡേറ്റുകളും സ്ഥിതിവിവരക്കണക്കുകളും എനിക്ക് അയയ്‌ക്കാൻ ഇ & എന്റർപ്രൈസസിന് എന്റെ കോൺടാക്റ്റ് വിശദാംശങ്ങൾ ഉപയോഗിക്കാനും സംഭരിക്കാനും കഴിയുമെന്ന് ഞാൻ സ്ഥിരീകരിക്കുന്നു.",
      //     success:
      //       "നിങ്ങളുടെ ഇമെയിൽ വിലാസം ഞങ്ങൾ വിജയകരമായി മനസ്സിലാക്കി. ഞങ്ങളിൽ നിന്ന് റിപ്പോർട്ട് ഉടൻ ലഭിക്കുമെന്ന് നിങ്ങൾക്ക് പ്രതീക്ഷിക്കാം. നന്ദി!",
      //     SendReport: "റിപ്പോർട്ട് അയയ്ക്കുക",
      //     Cancel: "റദ്ദാക്കുക",
      //     Back: "തിരികെ",
      //   },
      // },
    },
  });

export default i18n;
