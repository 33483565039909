import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api_key, sdkWrapperURL } from "../utils/api-url-list";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isOTPSent, setOTPSent] = useState(false);
  const otpTimerRef = useRef(null);
  const [otp, setOTP] = useState("");
  const [otpError, setOTPError] = useState("");
  const [otpTimer, setOTPTimer] = useState(0);

  const sendOTP = async () => {
    try {
      setLoading(true);
      setPhoneNumberError("");
      if (!/^\d{10}$/.test(phoneNumber)) {
        setPhoneNumber(phoneNumber.trim());
        setPhoneNumberError("Please enter a valid phone number.");
      } else {
        const resp = await fetch(sdkWrapperURL("/auth/send-otp"), {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            api_key,
            action: "send",
            channel_id: "login_mobile",
            phone_no: phoneNumber,
          }),
        });
        const resp_json = await resp.json();
        if (resp_json.statusCode?.toString().startsWith("2")) {
          setOTPTimer(120);
          clearInterval(otpTimerRef.current);
          otpTimerRef.current = setInterval(
            () => setOTPTimer((t) => t - 1),
            1000
          );
          setOTP("");
          setOTPSent(true);
        } else throw new Error(resp_json.message);
      }
    } catch (err) {
      cancelOTP();
      setPhoneNumberError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      setLoading(true);
      setOTPError("");
      if (!/^\d{6}$/.test(otp)) {
        setOTP(otp.trim());
        setOTPError("Incorrect OTP provided. Please try again");
      } else {
        const resp = await fetch(sdkWrapperURL("/auth/sign-in"), {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            api_key,
            login_type: "auth",
            login_method: "otp_mobile",
            phone_no: phoneNumber,
            otp: otp,
            referral_code: "",
          }),
        });
        const resp_json = await resp.json();
        if (resp_json.statusCode?.toString().startsWith("2")) {
          localStorage.setItem("auth_token", resp_json.user.id_token);
          localStorage.setItem("auth_id", resp_json.user.user_id);
          navigate("/", { replace: true });
        } else throw new Error(resp_json.message);
      }
    } catch (err) {
      setOTPError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const cancelOTP = (goback = true) => {
    setOTPTimer(0);
    clearInterval(otpTimerRef.current);
    setOTP("");
    setOTPError("");
    if (goback) {
      setOTPSent(false);
    }
  };

  useEffect(() => {
    if (otpTimer <= 0) {
      cancelOTP(false);
    }
  }, [otpTimer]);

  return (
    <section className="min-h-screen max-w-sm mx-auto p-8 relative flex flex-col items-stretch justify-center bg-white">
      {isOTPSent ? (
        <>
          <h1 className="text-3xl text-primary font-semibold">Verify OTP</h1>
          <h4 className="mt-3 text-lg text-[#2b2b2b] font-light">
            Enter the 6-digits OTP sent to your phone number
          </h4>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!isLoading) {
                if (otpTimer > 0) verifyOTP();
                else sendOTP();
              }
            }}
          >
            <input
              id="otp"
              name="otp"
              className="block w-full mt-6 px-4 py-2 rounded-lg outline-none focus:border-primary border text-lg text-primary"
              placeholder={otpTimer > 0 ? "Enter OTP" : "Timed Out"}
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              autoFocus
              maxLength={6}
              onChange={(e) =>
                e.target.value.length <= 6 && setOTP(e.target.value)
              }
              value={otp}
              disabled={isLoading || otpTimer <= 0}
            />
            {otpError.length > 0 && (
              <p className="mt-1.5 mx-1.5 text-sm text-secondary">{otpError}</p>
            )}
            {otpTimer > 0 && (
              <p className="mt-1.5 mx-1.5 font-light text-base text-secondary text-right">
                Resend OTP in {Math.floor(otpTimer / 60)}:
                {(otpTimer % 60).toString().padStart(2, "0")} secs
              </p>
            )}
            <button
              className="w-full flex items-center justify-center space-x-2 mt-6 px-4 py-2 rounded-full bg-primary disabled:bg-primary/80 text-white text-lg font-semibold"
              type="submit"
              disabled={isLoading}
            >
              {otpTimer > 0 ? "Verify OTP" : "Resend OTP"}
            </button>
            <button
              className="block mx-auto mt-6 text-secondary text-lg font-semibold"
              type="button"
              onClick={cancelOTP}
              disabled={isLoading}
            >
              Cancel
            </button>
          </form>
        </>
      ) : (
        <>
          <h1 className="text-3xl text-primary font-semibold">Login</h1>
          <h4 className="mt-3 text-lg text-[#2b2b2b] font-light">
            Login or sign up by simply verifying your phone number
          </h4>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!isLoading) sendOTP();
            }}
          >
            <input
              id="phone"
              name="phone"
              className="block w-full mt-6 px-4 py-2 rounded-lg outline-none focus:border-primary border text-lg text-primary"
              placeholder="Enter Phone Number"
              type="tel"
              inputMode="numeric"
              autoComplete="tel-national"
              autoFocus
              maxLength={10}
              onChange={(e) =>
                e.target.value.length <= 10 && setPhoneNumber(e.target.value)
              }
              value={phoneNumber}
              disabled={isLoading}
            />
            {phoneNumberError.length > 0 && (
              <p className="mt-1.5 mx-1.5 text-sm text-secondary">
                {phoneNumberError}
              </p>
            )}
            <button
              className="w-full flex items-center justify-center space-x-2 mt-6 px-4 py-2 rounded-full bg-primary disabled:bg-primary/80 text-white text-lg font-semibold"
              type="submit"
              disabled={isLoading}
            >
              Send OTP
            </button>
          </form>
        </>
      )}
      <Link
        to="/visualize"
        className="mt-12 px-4 py-2 rounded-xl border border-stroke bg-white text-primary/80 text-center font-medium"
      >
        Go to Large Screen Display
      </Link>
      <Link
        to="/visualize2"
        className="mt-12 px-4 py-2 rounded-xl border border-stroke bg-white text-primary/80 text-center font-medium"
      >
        Go to Large Screen Display 2
      </Link>
    </section>
  );
};

export default Login;
