import React, { useState } from "react";
import { ReactComponent as VendorLogo } from "../assets/logos/VendorLogo.svg";
import { ReactComponent as CarePlixLogo } from "../assets/logos/CarePlix.svg";
import { Alert, Button, Checkbox } from "@material-tailwind/react";
import { sdkWrapperURL } from "../utils/api-url-list";

const Contact = () => {
  const [name, setName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [email, setEmail] = useState("");
  const [hasConsent, setConsent] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const submit = async (e) => {
    try {
      e.preventDefault();
      const resp = await fetch(sdkWrapperURL("/users/save-products"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
        body: JSON.stringify({
          interest_filtration: "ACCEPT",
          product_id: "dbac2a18-3a72-4619-b4c4-56134397e955",
          application_info: { name, email, org_name: orgName },
        }),
      });
      const respJSON = await resp.json();
      if (respJSON?.statusCode?.toString().startsWith("2")) {
        setName("");
        setOrgName("");
        setEmail("");
        setConsent(false);
        setModalOpen(true);
      } else
        throw new Error(
          respJSON?.message ?? "Error in Fetching the Scan Token"
        );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="min-h-screen p-10 text-white bg-secondary bg-no-repeat bg-cover bg-center">
      <VendorLogo className="w-2/3 max-w-[70px] mx-auto" />
      <CarePlixLogo className="mt-10 w-2/3 max-w-[120px] mx-auto" />

      <form
        onSubmit={submit}
        className="mt-12 flex flex-col items-stretch justify-between gap-6"
      >
        <input
          id="name"
          name="name"
          type="text"
          inputMode="text"
          autoComplete="off"
          className="block w-full px-5 py-2.5 rounded-lg border border-stroke outline-none bg-white placeholder-[#BCBCBC] text-secondary text-sm"
          placeholder="Enter Your Name (required)"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <input
          id="org-name"
          name="org-name"
          type="text"
          inputMode="text"
          autoComplete="off"
          className="block w-full px-5 py-2.5 rounded-lg border border-stroke outline-none bg-white placeholder-[#BCBCBC] text-secondary text-sm"
          placeholder="Enter Company Name"
          value={orgName}
          onChange={(e) => {
            setOrgName(e.target.value);
          }}
        />
        <input
          id="email"
          name="email"
          type="email"
          inputMode="email"
          autoComplete="off"
          className="block w-full px-5 py-2.5 rounded-lg border border-stroke outline-none bg-white placeholder-[#BCBCBC] text-secondary text-sm"
          placeholder="Enter Your Email ID (required)"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <Checkbox
          id="consent"
          name="consent"
          color="blue"
          label="By checking this box, I consent to the collection and use of my personal information for further communication purposes."
          labelProps={{
            className:
              "text-white text-justify text-[10px] font-medium leading-snug",
          }}
          containerProps={{ className: "shrink-0 self-start" }}
          className="border border-stroke bg-white "
          ripple
          value={hasConsent}
          checked={hasConsent}
          onChange={(e) => {
            setConsent(e.target.checked);
          }}
        />

        <Button
          color="blue"
          className="w-full bg-primary text-white border border-primary text-sm font-medium normal-case"
          type="submit"
          disabled={
            name.length === 0 ||
            email.length === 0 ||
            !/^[a-z0-9]+([._-]*[a-z0-9])+[@](\w+[.])+\w+$/.test(email) ||
            !hasConsent
          }
        >
          Submit
        </Button>
        <a
          href="https://drive.google.com/file/d/1KOZNfIUCT6mO_U_v9Yh2SCSqjRR_BGJO/view"
          target="_blank"
          rel="noreferrer"
          className="w-full px-6 py-3 flex items-center justify-center gap-3 rounded-lg bg-primary text-white border border-primary text-sm font-medium normal-case"
        >
          <svg className="shrink-0 h-5 w-5" viewBox="0 0 56 42" fill="none">
            <path
              d="M17.3333 15.75C17.3333 9.95101 22.109 5.25 28 5.25C33.8909 5.25 38.6667 9.95101 38.6667 15.75V18.375H41.3333C46.488 18.375 50.6667 22.4884 50.6667 27.5625C50.6667 32.6366 46.488 36.75 41.3333 36.75H41.0667C39.5939 36.75 38.4 37.9252 38.4 39.375C38.4 40.8248 39.5939 42 41.0667 42H41.3333C49.4336 42 56 35.5362 56 27.5625C56 20.4207 50.732 14.4899 43.8123 13.3304C42.6299 5.78059 36.0008 0 28 0C19.9993 0 13.3702 5.78059 12.1876 13.3304C5.26803 14.4899 0 20.4207 0 27.5625C0 35.5362 6.56648 42 14.6667 42H14.9333C16.4061 42 17.6 40.8248 17.6 39.375C17.6 37.9252 16.4061 36.75 14.9333 36.75H14.6667C9.512 36.75 5.33333 32.6366 5.33333 27.5625C5.33333 22.4884 9.512 18.375 14.6667 18.375H17.3333V15.75ZM30.6667 18.375C30.6667 16.9252 29.4728 15.75 28 15.75C26.5272 15.75 25.3333 16.9252 25.3333 18.375V33.0377L21.8856 29.6439C20.8442 28.6188 19.1558 28.6188 18.1144 29.6439C17.073 30.6689 17.073 32.3311 18.1144 33.3561L26.1144 41.2311C27.1557 42.2562 28.8443 42.2562 29.8856 41.2311L37.8856 33.3561C38.9269 32.3311 38.9269 30.6689 37.8856 29.6439C36.8443 28.6188 35.1557 28.6188 34.1144 29.6439L30.6667 33.0377V18.375Z"
              fill="currentColor"
              fillRule="evenodd"
              clipRule="evenodd"
            />
          </svg>
          <span>Download Product Brochure</span>
        </a>
      </form>

      <Alert
        color="blue"
        open={isModalOpen}
        className="bg-black/50 fixed inset-0 z-50"
      >
        <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-2/3 p-6 rounded-2xl bg-white text-center">
          <p className="mb-6 text-secondary font-medium leading-snug">
            Thank you for submitting the details. We will be in touch with you
            shortly.
          </p>
          <Button
            color="blue"
            className="py-1 px-3 bg-primary text-white border border-primary text-sm font-medium normal-case"
            type="button"
            onClick={() => setModalOpen(false)}
          >
            Ok
          </Button>
        </div>
      </Alert>
    </div>
  );
};

export default Contact;
