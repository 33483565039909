import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Carousel, Typography } from "@material-tailwind/react";

import { useSocket } from "../App";

import { ReactComponent as FaceMesh } from "../assets/icons/FaceMesh3.svg";
import { ReactComponent as VendorLogo } from "../assets/logos/VendorLogo.svg";
import { ReactComponent as CarePlixLogo } from "../assets/logos/CarePlix.svg";

const GetStarted = () => {
  const navigate = useNavigate();
  const socket = useSocket();

  useEffect(() => {
    socket?.emit("reset");
  }, [socket]);

  return (
    <section
      dir="ltr"
      className="relative grow shrink-0 bg-no-repeat bg-cover bg-primary bg-center flex flex-col items-center text-white"
    >
      <FaceMesh className="absolute left-0 inset-y-[13%] w-3/5 -z-0" />
      <VendorLogo className="my-8 sm:my-16 p-3 sm:p-6 h-12 sm:h-24 rounded-lg sm:rounded-2xl bg-white" />
      <Carousel
        prevArrow={() => <></>}
        nextArrow={() => <></>}
        navigation={({ activeIndex, length }) => (
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-50 flex items-stretch justify-between gap-2 sm:gap-4">
            {[...Array(length).keys()].map((i) => (
              <span
                key={`carousel-nav-${i}`}
                className={`shrink-0 h-2 w-2 sm:h-4 sm:w-4 rounded-full ${
                  activeIndex === i ? "bg-secondary" : "bg-white"
                }`}
              />
            ))}
          </div>
        )}
        autoplay
        autoplayDelay={3000}
        loop
        className="self-stretch grow items-center text-center whitespace-pre-line"
      >
        {[
          {
            title: "Futuristic\nHealth Monitoring",
            subtitle: "AI-Powered Face Scan\nfor Vital Insights",
          },
          {
            title: "60 Seconds to a\nHealthier You",
            subtitle: "Unlock Your Wellness\nPotential Now",
          },
        ].map((item, index) => (
          <Fragment key={`carousel-item-${index}`}>
            <Typography
              variant="h1"
              className="text-3xl sm:text-6xl leading-tight font-semibold"
            >
              {item.title}
            </Typography>
            <Typography
              variant="h2"
              className="mt-2.5 sm:mt-5 text-2xl sm:text-5xl leading-tight font-normal"
            >
              {item.subtitle}
            </Typography>
          </Fragment>
        ))}
      </Carousel>
      <Button
        size="lg"
        color="white"
        className="shrink-0 mt-6 sm:mt-12 w-2/5 text-sm sm:text-2xl text-primary font-semibold normal-case relative z-50"
        onClick={() => navigate("/select-options")}
      >
        Get Started
      </Button>
      <CarePlixLogo className="shrink-0 my-10 sm:my-20 h-12 sm:h-24" />
    </section>
  );
};

export default GetStarted;
